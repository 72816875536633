@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

body{
  margin:0px !important;
  font-family: 'Work Sans', sans-serif;
  color: #242424 !important;

}
p{
  color: #242424 !important;
}
.navbar{
  position: fixed !important;
  background-color: white;
  width:100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  background-image: url(assets/trees.svg) !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  min-height:100px;
  max-height:100px;
  box-shadow: none !important;
  // box-shadow: whitesmoke 0px 1px 0px, whitesmoke 0px 1px 0px inset !important;
}
.star{
  padding-left:5px;
  // color:#66A19C;
  color:#E0724F;
  font-weight: bold;
  font-size: 16px;
}

.fas , .far, .fab{
  font-size:20px;
  vertical-align: middle;
}

.card {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom:0px !important;
  padding-bottom:0px !important;
}
.card-body {
  padding:0px !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  color: #242424 !important; //626262
  text-align: justify !important;
}
.carousel-indicators {
  margin-bottom: 0.5rem !important;
}
// .carousel{
//   background-color: #ecf4f4 !important;
//   padding: 0px !important;
// }

// .controls-top{
//   display:visible !important;
// }
// .carousel-multi-item .carousel-indicators li {
//   background-color: #86bbbc !important;
// }
// .carousel-multi-item .carousel-indicators .active {
//   background-color: #268586 !important;//green
// }

//Classic tabs
.classic-tabs
{
  justify-content: center !important;
  background-color: #34495e !important;
  background-color: white !important;
  padding: 0px !important;
}
.classic-tabs .nav li {
  font-size: 1.1rem;
  padding-left: 25px;
  padding-right:25px;
}
.classic-tabs .nav li a {
  border-bottom: 3px solid !important;
  color:#BFBFBF !important;
  // color:teal !important;
  font-weight: bold !important;
  opacity:0.9;

  :hover{
    opacity:0.4;
  }
}

.classic-tabs .nav li a.active {
  border-bottom: 3px solid !important;
  color: #d9eae8 !important;
  color:teal !important;
  font-weight: 800 !important;
  opacity:1;

}
.tab-content{ //removed because it was affecting agm map info
  margin-top:15px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  min-height:230px !important;
}


#flora_fauna_tabs .tab-content{
  box-shadow: none !important;
}
//Classic Tabs

//ERROR MESSAGES
.red-border-class
{
  border:1px solid #E0724F !important;;
}
.error-message-div{
  min-height:20px;
}
.errorMessage{
  color:#E0724F;
  font-size:11px;
  font-weight:500;
  min-height:16px;
}
//Maps info label
.info-lbl{
  color: #116bb5;
  text-transform:uppercase;
  font-weight: 500;
}

//<google-map INFO popup//
// .agm-info-window-content{
//   height:200px !important;
//   overflow: scroll !important;
// }
.gm-style .gm-style-iw {
  color:black !important;
  font-size:0.9rem !important;
  letter-spacing: 1px !important;
}
//   .gm-style-iw
// {
//   min-height:350px !important;
//   max-height:350px !important;
//   min-width:650px !important;
// }
// .gm-style-iw-c{
//   min-height:350px !important;
//   max-height:350px !important;
//   min-width:650px !important;
// }
//<google-map INFO//

//images
.notes{
  font-size:11px;
  color:#757575;
  font-weight:500;
}
.notes2{
  font-size:11px;
  font-weight:400;
  color:#A2A2A2;
}

//For Radio buttons
.form-check{
  display: flex;
  justify-content: center;
}
.form-check-label{
  font-size: 14px;
}

//Delete button
.trash{
  background-color: #FFF !important;
  border:none;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height:45px !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #F8F8F8 !important;
  border-color: #F8F8F8 !important;
  color: #B0B4B4 !important;
}
.input-group > .form-control, .disabled, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  // color: #757575 !important;
  font-size: 16px !important;
}
.control-label{
  font-size:14px !important;
  color:#808080 !important;
}
.custom-btn-orol{
  margin-top:50px !important;
  border: 1px solid #268586 !important;
  background-color: white;
  color: #757575;
  font-size: 0.9rem;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 1px;
  padding: 10px;
}

.cursor-pointer{
  cursor: pointer;
}

//Used for River Monitoring Datepicker and Timepicker
.md-form{
  margin: 0px !important;
}
.picker.picker--opened .picker__holder {
  display: contents !important;
}
.datepicker-inline-icon {
  top: 0px !important;
}

#fish_sanctuaries_stepper .card-body{
  padding-left:0px !important;
  padding-right:0px !important;
}
#fish_sanctuaries_stepper .card-body ul.stepper.horizontal .step.active .step-title:before {
  background-color: #1C3764 !important;
}

.carousel-item{
  display: flex !important;
  justify-content: center !important;
}

.contributor-circle{
  height:50px;
  width:50px;
  background-color:#8a9789;
  border-radius:50%;
}
.contributor-icon{
  color:#242424;
}
.message-div-success{
  height:55px;
  background-color: #f5f5f5;
  color:#0F9D4D;
  font-weight: 500;
  line-height: 3.5em;
  padding:0px 20px 0px 20px;
  margin-top:20px;
}
.message-div-error{
  height:55px;
  background-color: #f5f5f5;
  color:#E0724F;
  font-weight: 500;
  line-height: 3.5em;
  padding:0px 20px 0px 20px;
  margin-top:20px;
}
.form-group{
  margin-bottom:0px !important;
}

//STEPPER
ul.stepper.horizontal .step .step-title:before {
  height:25px !important;
  width:25px !important;

}

ul.stepper.horizontal .step-new-content {
  top:5rem !important;
}
//STEPPER

#recaptcha-container > div > div{
  width: 100% !important;
}

//To Remove unwarranted outline
*:focus {
  outline: 0 !important;
  outline: none !important;
}

.img-responsive-176{
  margin: 0 auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 176px;
}
.img-responsive-150{
  margin: 0 auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 150px;
}
.img-responsive-50{
  margin: 0 auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 50px;
}
.img-responsive-100{
  margin: 0 auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}
.img-responsive-250{
  margin: 0 auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 250px;
}

//Datepicker
//To remove underline from datepicker
.mdc-line-ripple::before, .mdc-line-ripple::after {
  border:none !important;
}

//To set height of the datepicker
.mat-mdc-form-field-flex {
   height:45px;
   align-items:center;
}

// add border color to the datepicker
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  background-color: white !important;
  border: 1px solid #bdbdbd;
}

//Timepicker
.timepicker{
  padding:0px !important
}

//STEPPER
.mat-stepper-horizontal{
  text-align: left !important;
}
.mat-step-header{
  width:24px !important;
  pointer-events: none !important;
}
.mat-step-icon {
  color:white !important;
  background-color: #1C3764 !important;
  background-color: rgb(19,85,166) !important;
}
.mat-step-icon-content{
  font-size: small;
}
.mat-step-icon-selected{
  background-color: rgb(122,190,50) !important;
}
.stepper-heading{
}

.text-left{
  text-align: left;
}

//Used for submenu in toolbar
.mat-mdc-menu-panel.mat-mdc-menu-panel {
  margin-top:35px !important;
  min-width: 222px !important;
}

//Divider
.dropdown-divider {
    // border-size:1px;
    border-style: ridge;
    margin:0px !important;
    border-color: transparent;
}

.bold{
  font-weight:bold;
}

//Buttons
//Used for add alert
.btn-custom-teal{
  background-color: rgb(152,195,86) !important;
  color: #1C3764 !important;
  font-weight: bold !important;
  border:1px solid rgb(152,195,86) !important;
}
.btn-custom-blue{
  background-color: #1C3764 !important;
  color: white !important;
  font-weight: bold !important;
  border:1px solid #1C3764 !important;
}
.btn-next{
  background-color: #1C3764 !important;
  color: white !important;
  font-weight: bold !important;
  border:1px solid #1C3764 !important;
}
.btn-previous{
  background-color: rgb(152,195,86) !important;
  color: 1C3764 !important;
  font-weight: bold !important;
  border:1px solid rgb(152,195,86) !important;
}
.btn-custom-cancel{
  background-color: white !important;
  color: red !important;
  font-weight: bold !important;
  border:1px solid red !important;
}
.btn-custom-submit{
  background-color: #1C3764 !important;
  color: white !important;
  font-weight: bold !important;
  border:1px solid #1C3764 !important;
}
// .btn-yes{
//   background-color: #1C3764 !important;
//   color: white !important;
//   font-weight: bold !important;
//   border:1px solid #1C3764 !important;
// }
// .btn-no{
//   background-color: #1C3764 !important;
//   color: white !important;
//   font-weight: bold !important;
//   border:1px solid #1C3764 !important;
// }
.btn-delete{
  background-color: white !important;
  border: 1px solid red !important;
  color:#1C3764;
  // font-size: 12px;
  // border-radius: 8px;
  // padding:8px 24px;
  // text-align: center;
  }
  .btn-cancel{
    border: 1px solid green !important;
    background-color: white !important;
    color:#1C3764 !important;
  }

//For Image Uploads
.custom-file-upload, .custom-btn {
  border: 1px solid #1C3764;
  padding: 5px 10px;
  cursor: pointer;
  // font-size: 12px;
  border-radius: 10px;
  background-color: #1C3764;
  color:white;
  display: flex !important;
  align-items: center !important;
}

//Mat Dialog
.mat-mdc-dialog-actions {
  justify-content: start !important;
  padding-bottom: 30px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

//Carousel next and prev icons
.carousel-control-next-icon::after {
  content: "\276F" !important;
}
.carousel-control-prev-icon::after {
  content: "\276E" !important;
}

.name1{
  color: #126bb6;
}
.name2{
  color: #8bc641;
}


@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/brands';
@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';
